import { useMutation } from '@apollo/client'
import {
  LandingCreateLeadDocument,
  Core_TransactionType,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import { AddressData, localStore, createLeadHelper } from '@flock/utils'
import { useMediaQuery, useTheme } from '@mui/material'
import { navigate } from 'gatsby'
import { useState } from 'react'
import { ONBOARDING_PATH } from '../../../routeConstants'
import {
  HomepageHeroSectionProps,
  HomepageHeroSectionPresentationalProps,
} from './homepageHeroSectionTypes'

const useHomepageHeroSection: (
  props: HomepageHeroSectionProps
) => HomepageHeroSectionPresentationalProps = () => {
  const [addressData, setAddressData] = useState<AddressData | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [createLead] = useMutation(LandingCreateLeadDocument)

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'))

  const onSubmit = async () => {
    let params: URLSearchParams | null = null
    if (typeof window !== `undefined`) {
      params = new URLSearchParams(window.location.search)
    }

    if (addressData?.streetAddress) {
      setLoading(true)

      try {
        const leadData = {
          addressData,
          source: 'onboarding',
          transactionType: Core_TransactionType.TransactionTypeSingle_721,
        }
        const queryParams = localStore?.getItem('queryParams')
        let parsedQueryParams: any = {}
        if (queryParams) {
          parsedQueryParams = JSON.parse(queryParams)
        }
        const createLeadResponse = await createLeadHelper(
          leadData,
          parsedQueryParams,
          createLead
        )
        const newLeadUuid = createLeadResponse?.data.createLead.lead.uuid
        let path = `${ONBOARDING_PATH}?leadUuid=${newLeadUuid}`
        if (typeof Storage !== 'undefined') {
          localStore?.removeItem('step')
          localStore?.removeItem('flowData')
        }
        // carry forward all url params to be parsed in the same flow as onboarding flow
        params?.forEach((key, value) => {
          path += `&${value}=${key}`
        })
        navigate(path)
      } catch (e) {
        window.location.href = `https://form.jotform.com/232717575357162?address[addr_line1]=${addressData?.streetNumber}%20${addressData?.streetAddress}&address[city]=${addressData?.city}&address[state]=${addressData?.state}&address[postal]=${addressData?.zipcode}`
        setLoading(false)
      }
    }
  }

  const onChange = async (data: AddressData | null) => {
    setAddressData(data)
  }

  const onKeyDown = (event: any) => {
    if (event.keyCode === 13) {
      event.preventDefault()
      onSubmit()
    }
  }

  return {
    isMobile,
    isTablet,
    loading,

    onKeyDown,
    onChange,
    onSubmit,
  }
}

export default useHomepageHeroSection
