import React from 'react'
import { SvgIcon, SvgIconProps, useTheme } from '@mui/material'

const OmInvestIcon = (props: SvgIconProps) => {
  // @ts-ignore
  const { color, fill, stroke, sx, width, height, ...otherProps } = props // eslint-disable-line
  const theme = useTheme()

  let selectedColor = color

  // @ts-ignore
  if (color && theme.palette[color]) {
    // @ts-ignore
    selectedColor = theme.palette[color].main
  }

  const fillStyle = fill || selectedColor || undefined

  const styles = {
    width: 'inherit',
    height: 'inherit',
    fill: 'inherit',
    ...sx,
  }

  const cleanedPaths = () => (
    <svg
      width={width || 73}
      height={height || 72}
      viewBox="0 0 73 72"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M43.3335 8.5C42.5051 8.5 41.8335 9.17157 41.8335 10C41.8335 10.8284 42.5051 11.5 43.3335 11.5H48.7605L33.32 27.6868L28.5675 22.7047C28.2845 22.4079 27.8923 22.24 27.4821 22.24C27.072 22.24 26.6798 22.4079 26.3968 22.7047L13.7481 35.9647C13.1763 36.5641 13.1987 37.5136 13.7982 38.0854C14.3976 38.6572 15.3471 38.6348 15.9189 38.0353L27.4821 25.9132L32.2346 30.8953C32.5177 31.1921 32.9099 31.36 33.32 31.36C33.7301 31.36 34.1223 31.1921 34.4054 30.8953L50.8335 13.6732V19C50.8335 19.8284 51.5051 20.5 52.3335 20.5C53.1619 20.5 53.8335 19.8284 53.8335 19V10C53.8335 9.17157 53.1619 8.5 52.3335 8.5H43.3335ZM52.3335 65.5C53.1619 65.5 53.8335 64.8284 53.8335 64C53.8335 63.1716 53.1619 62.5 52.3335 62.5H10.3335L10.3335 23.5C10.3335 22.6716 9.66192 22 8.8335 22C8.00507 22 7.3335 22.6716 7.3335 23.5L7.3335 64C7.3335 64.8284 8.00507 65.5 8.8335 65.5H52.3335ZM14.8335 48.25C14.8335 47.0074 15.8409 46 17.0835 46H21.5835C22.8261 46 23.8335 47.0074 23.8335 48.25V55.75C23.8335 56.9926 22.8261 58 21.5835 58H17.0835C15.8409 58 14.8335 56.9926 14.8335 55.75V48.25ZM17.8335 49V55H20.8335V49H17.8335ZM32.0835 37C30.8409 37 29.8335 38.0074 29.8335 39.25V55.75C29.8335 56.9926 30.8409 58 32.0835 58H36.5835C37.8261 58 38.8335 56.9926 38.8335 55.75V39.25C38.8335 38.0074 37.8261 37 36.5835 37H32.0835ZM32.8335 55V40H35.8335V55H32.8335ZM44.8335 31.75C44.8335 30.5074 45.8409 29.5 47.0835 29.5H51.5835C52.8261 29.5 53.8335 30.5074 53.8335 31.75V55.75C53.8335 56.9926 52.8261 58 51.5835 58H47.0835C45.8409 58 44.8335 56.9926 44.8335 55.75V31.75ZM47.8335 32.5V55H50.8335V32.5H47.8335Z"
        fill={fillStyle || '#D14261'}
      />
    </svg>
  )

  return (
    <SvgIcon
      component={cleanedPaths}
      inheritViewBox
      sx={styles}
      {...otherProps}
    />
  )
}

export default OmInvestIcon
