import React from 'react'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { Carousel as CarouselComponent } from 'react-responsive-carousel'
import {
  EmptyDotIcon,
  FilledDotIcon,
  TrackedIconButton,
} from '@flock/shared-ui'
import { Box } from '@mui/material'

type CarouselProps = {
  children: React.ReactNode
}

const Carousel = (props: CarouselProps) => {
  const { children } = props
  return (
    <Box
      width="100%"
      sx={{
        '& .carousel .slide': {
          textAlign: 'initial',
        },
        '& .carousel.carousel-slider': {
          paddingTop: '16px',
          paddingBottom: '64px',
        },
        '& .carousel .image': {
          width: 'inherit',
        },
        '& .carousel .slide img': {
          width: 'inherit',
        },
      }}
    >
      <CarouselComponent
        showThumbs={false}
        infiniteLoop
        preventMovementUntilSwipeScrollTolerance
        swipeScrollTolerance={50}
        showArrows={false}
        showStatus={false}
        renderIndicator={(clickHandler, isSelected, index) => {
          if (isSelected) {
            return (
              <TrackedIconButton
                aria-label={`Carousel Dot ${index}`}
                trackingConfig={{ name: `carousel-dot` }}
                onClick={clickHandler}
              >
                <FilledDotIcon />
              </TrackedIconButton>
            )
          } else {
            return (
              <TrackedIconButton
                aria-label={`Carousel Dot ${index}`}
                trackingConfig={{ name: `carousel-dot` }}
                onClick={clickHandler}
              >
                <EmptyDotIcon />
              </TrackedIconButton>
            )
          }
        }}
      >
        {children as React.ReactChild[]}
      </CarouselComponent>
    </Box>
  )
}

export default Carousel
