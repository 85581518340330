import { MOBILE_BREAKPOINT } from '@flock/flock-component-library'
import { useMediaQuery } from '@mui/material'
import { useEffect, useState } from 'react'

export const hasIntersectionObserver = () =>
  typeof window !== 'undefined' &&
  'IntersectionObserver' in window &&
  'IntersectionObserverEntry' in window &&
  'intersectionRatio' in window.IntersectionObserverEntry.prototype

export const useOnScreen = (
  ref: any,
  rootMargin: string = '0px',
  breakpoint: string = MOBILE_BREAKPOINT,
  ready: boolean = true
) => {
  const [isIntersecting, setIntersecting] = useState(false)

  const isMobile = useMediaQuery(breakpoint)

  useEffect(() => {
    if (!isMobile && hasIntersectionObserver() && ref.current && ready) {
      const observer = new IntersectionObserver(
        ([entry]) => {
          // Update our state when observer callback fires
          setIntersecting(entry.isIntersecting)
        },
        {
          rootMargin,
        }
      )

      observer.observe(ref.current)
      // Remove the observer as soon as the component is unmounted
      return () => {
        observer.disconnect()
      }
    }
    return () => {}
  }, [isMobile, ready, ref, rootMargin])

  return isIntersecting
}
