import { EmptyDotIcon, FilledDotIcon, TrackedButton } from '@flock/shared-ui'
import { Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material'
import React, { RefObject, useEffect, useRef, useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Fade from 'react-reveal/Fade'
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image'
import { HOW_TO_SIGN_UP_PATH } from '../../routeConstants'
import SectionLayout from '../SharedComponents/SectionLayout'
import { hasIntersectionObserver, useOnScreen } from '../screenUtils'

type SharedStepProps = {
  title: string
  description: string
  imgData: IGatsbyImageData
}

type StepProps = SharedStepProps & {
  dynamicImgData: IGatsbyImageData
  imgData1: IGatsbyImageData
  imgData2: IGatsbyImageData
  imgData3: IGatsbyImageData
  divRef: React.Ref<any>
  showFallbackImages: boolean
  allRefs: RefObject<HTMLDivElement>[]
  idx: number
  selectedIndex: number
  ctaText?: string
}

const Step = (props: StepProps) => {
  const {
    title,
    description,
    imgData,
    divRef,
    allRefs,
    showFallbackImages,
    idx,
    ctaText,
    dynamicImgData,
    imgData1,
    imgData2,
    imgData3,
    selectedIndex,
  } = props
  return (
    <>
      {showFallbackImages ? (
        <Grid
          sm={6}
          item
          key="static-grid"
          display="flex"
          justifyContent="center"
        >
          <Box width={448} height={711}>
            <GatsbyImage image={imgData} alt="Overmoon Phone Demo" />
          </Box>
        </Grid>
      ) : (
        <Grid
          sm={6}
          item
          key="dynamic-grid"
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{
            position: 'sticky',
            top: '8%',
            height: '85vh',
            zIndex: idx === 0 ? 999 : 1,
          }}
        >
          {dynamicImgData && idx === 0 && (
            <>
              <Fade duration={500} delay={500} distance="5rem" bottom>
                <Box
                  width={448}
                  height={711}
                  display={selectedIndex === 0 ? 'block' : 'none'}
                >
                  <GatsbyImage
                    image={imgData1}
                    alt="Overmoon Phone Demo"
                    loading="eager"
                  />
                </Box>
                <Box
                  width={448}
                  height={711}
                  display={selectedIndex === 1 ? 'block' : 'none'}
                >
                  <GatsbyImage
                    image={imgData2}
                    alt="Overmoon Phone Demo"
                    loading="eager"
                  />
                </Box>
                <Box
                  width={448}
                  height={711}
                  display={selectedIndex === 2 ? 'block' : 'none'}
                >
                  <GatsbyImage
                    image={imgData3}
                    alt="Overmoon Phone Demo"
                    loading="eager"
                  />
                </Box>
              </Fade>
              <Box display="flex" flexDirection="column" zIndex={999}>
                <Box
                  onClick={() =>
                    allRefs[0]?.current?.scrollIntoView({
                      behavior: 'smooth',
                      block: 'center',
                      inline: 'center',
                    })
                  }
                  sx={{ cursor: 'pointer' }}
                >
                  {selectedIndex === 0 ? <FilledDotIcon /> : <EmptyDotIcon />}
                </Box>

                <Box
                  onClick={() =>
                    allRefs[1]?.current?.scrollIntoView({
                      behavior: 'smooth',
                      block: 'center',
                      inline: 'center',
                    })
                  }
                  sx={{ cursor: 'pointer' }}
                >
                  {selectedIndex === 1 ? <FilledDotIcon /> : <EmptyDotIcon />}
                </Box>

                <Box
                  onClick={() =>
                    allRefs[2]?.current?.scrollIntoView({
                      behavior: 'smooth',
                      block: 'center',
                      inline: 'center',
                    })
                  }
                  sx={{ cursor: 'pointer' }}
                >
                  {selectedIndex === 2 ? <FilledDotIcon /> : <EmptyDotIcon />}
                </Box>
              </Box>
            </>
          )}
        </Grid>
      )}
      <Grid
        item
        sm={6}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Fade duration={500} distance="5rem" right>
          <Box display="flex" flexDirection="column" gap="32px" ref={divRef}>
            <Typography variant="h1">{title}</Typography>
            <Typography variant="p1m">{description}</Typography>
            {ctaText && (
              <Grid item sm={8}>
                <TrackedButton
                  variant="secondary"
                  size="small"
                  to={HOW_TO_SIGN_UP_PATH}
                >
                  {ctaText}
                </TrackedButton>
              </Grid>
            )}
          </Box>
        </Fade>
      </Grid>
    </>
  )
}

Step.defaultProps = {
  ctaText: '',
}

const MobileStep = (props: SharedStepProps) => {
  const { title, description, imgData } = props

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      gap="32px"
      maxWidth={isMobile ? '320px' : undefined}
      margin="auto"
    >
      <Typography variant="h1m" alignSelf="flex-start">
        {title}
      </Typography>

      <Box width={isMobile ? 277 : 448} height={isMobile ? 426 : 711}>
        <GatsbyImage image={imgData} alt="Overmoon Phone Demo" />
      </Box>
      <Typography variant="p2">{description}</Typography>
    </Box>
  )
}

type HomepageProcessSectionProps = {
  steps?: {
    title: string
    description: string
    imgSrcIndex: number
  }[]
  ctaText?: string
}

const HomepageProcessSection = (props: HomepageProcessSectionProps) => {
  const { steps, ctaText } = props

  const imageData = useStaticQuery(graphql`
    query GetHomepageProcessSectionImages {
      step1Image: file(relativePath: { eq: "process-1.webp" }) {
        childImageSharp {
          gatsbyImageData(placeholder: NONE)
        }
      }
      step2Image: file(relativePath: { eq: "process-2.webp" }) {
        childImageSharp {
          gatsbyImageData(placeholder: NONE)
        }
      }
      step3Image: file(relativePath: { eq: "process-3.webp" }) {
        childImageSharp {
          gatsbyImageData(placeholder: NONE)
        }
      }
    }
  `)

  const step1Image = getImage(imageData?.step1Image!) as IGatsbyImageData
  const step2Image = getImage(imageData?.step2Image!) as IGatsbyImageData
  const step3Image = getImage(imageData?.step3Image!) as IGatsbyImageData

  const imageDataArray = [step1Image, step2Image, step3Image]

  const [image, setImage] = useState<IGatsbyImageData>(step1Image)
  const [selectedIndex, setSelectedIndex] = useState(0)

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isTablet = useMediaQuery(theme.breakpoints.down('md'))

  const [showFallbackImages, setShowFallbackImages] = useState(false)

  useEffect(() => {
    setShowFallbackImages(!hasIntersectionObserver() || isMobile)
  }, [isMobile])

  const step1Ref = useRef(null)
  const step2Ref = useRef(null)
  const step3Ref = useRef(null)

  const step1Visible = useOnScreen(
    step1Ref,
    '0px',
    theme.breakpoints.down('md')
  )
  const step2Visible = useOnScreen(
    step2Ref,
    '0px',
    theme.breakpoints.down('md')
  )
  const step3Visible = useOnScreen(
    step3Ref,
    '0px',
    theme.breakpoints.down('md')
  )

  useEffect(() => {
    if (step3Visible && !step2Visible && !step1Visible) {
      setImage(step3Image)
      setSelectedIndex(2)
    } else if (step2Visible && !step3Visible && !step1Visible) {
      setImage(step2Image)
      setSelectedIndex(1)
    } else if (step1Visible && !step3Visible && !step2Visible) {
      setImage(step1Image)
      setSelectedIndex(0)
    }
  }, [
    step1Visible,
    step2Visible,
    step3Visible,
    step1Image,
    step2Image,
    step3Image,
  ])

  const refs = [step1Ref, step2Ref, step3Ref]

  if (isTablet) {
    return (
      <SectionLayout name="home-process" backgroundColor="gray1.main">
        <Grid
          item
          xs={12}
          display="flex"
          flexDirection="column"
          alignItems="center"
          gap="32px"
        >
          {steps?.map((step) => (
            <MobileStep
              key={step.title}
              {...step}
              imgData={imageDataArray[step.imgSrcIndex]!}
            />
          ))}
        </Grid>
        <Grid item xs={2} />
        <Grid item xs={12} sm={8} display="flex" justifyContent="center">
          <TrackedButton
            variant="secondary"
            to={HOW_TO_SIGN_UP_PATH}
            size="small"
          >
            {ctaText}
          </TrackedButton>
        </Grid>
        <Grid item xs={2} />
      </SectionLayout>
    )
  }

  return (
    <SectionLayout name="home-process" backgroundColor="gray1.main">
      {steps?.map((step, idx) => (
        <Step
          key={step.title}
          {...step}
          imgData={imageDataArray[step.imgSrcIndex]}
          divRef={refs[idx]}
          allRefs={refs}
          idx={idx}
          dynamicImgData={image}
          imgData1={step1Image}
          imgData2={step2Image}
          imgData3={step3Image}
          showFallbackImages={showFallbackImages}
          selectedIndex={selectedIndex}
        />
      ))}
    </SectionLayout>
  )
}

export default HomepageProcessSection

HomepageProcessSection.defaultProps = {
  steps: [
    {
      title: 'Seamless exchange and hand-off.',
      description: `We’ll guide you through every step of the process. In as little as two weeks, title is transferred and we assume or payoff any financing associated with the property. Afterwards, you’ll receive shares in Overmoon ’s portfolio of homes equal to your remaining home equity value. We take over all aspects of maintenance and property management.`,
      imgSrcIndex: 0,
    },
    {
      title: 'Reliable income and potential upside.',
      description: `The Fund intends to pay distributions via direct deposit, and you'll have anytime access to your owner’s portal to view notifications, performance, and important tax documents.`,
      imgSrcIndex: 1,
    },
    {
      title: 'Access liquidity.',
      description: `The Overmoon 721 Fund was built to be your long-term partner, and we're grateful to protect your investment for years to come. That said, we offer access to liquidity. After three years in the Overmoon 721 Fund, you can participate in the share redemption program. Alternatively, your estate plan may have the ability to direct shares to your heirs.`,
      imgSrcIndex: 2,
      ctaText: 'How to sign up',
    },
  ],
  ctaText: 'How to sign up',
}
