import { Box, SvgIconProps, Typography, useTheme } from '@mui/material'
import React from 'react'

export type IconTextDetailProps = {
  icon: React.FC<SvgIconProps>
  iconSize?: string
  title: React.ReactNode
  description: string
  gap?: string
  color?: string
}

const IconTextDetail = (props: IconTextDetailProps) => {
  const { icon, iconSize, title, description, gap, color } = props
  const Icon = icon
  const theme = useTheme()
  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={gap || '20px'}
      width={{
        xs: '320px',
        sm: '340px',
      }}
    >
      <Icon
        color={color}
        width={iconSize || '64px'}
        height={iconSize || '64px'}
      />
      <Typography
        variant="h4"
        color="midnightBlue.main"
        sx={{
          display: 'flex',
          alignItems: 'center',
          fontSize: '1.8rem',
          lineHeight: '1.925rem',
          [theme.breakpoints.down('md')]: {
            fontWeight: 900,
            fontSize: '1.5rem',
            lineHeight: '2rem',
          },
        }}
      >
        {title}
      </Typography>
      <Typography variant="p1m" color="midnightBlue.main">
        {description}
      </Typography>
    </Box>
  )
}

export default IconTextDetail

IconTextDetail.defaultProps = {
  iconSize: '64px',
  gap: undefined,
}
