import { TrackedButton } from '@flock/shared-ui'
import { Grid, Box, Typography } from '@mui/material'
import React from 'react'
import SectionLayout from '../../SharedComponents/SectionLayout'
import HeroImage from '../../../images/hero-image-mobile.webp'
import { ONBOARDING_PATH } from '../../../routeConstants'

const HomepageHeroSectionMobile = () => (
  <>
    <SectionLayout
      name="homepage-hero"
      backgroundColor="gray1.main"
      verticalPadding="0px"
      sx={{ paddingTop: '40px', paddingBottom: '346px' }}
    >
      <Grid container item xs={12} spacing={2}>
        <Grid
          item
          xs={12}
          display="flex"
          flexDirection="column"
          gap="24px"
          justifyContent="center"
        >
          {' '}
          <Typography variant="h1m" color="midnightBlue.main">
            Is your vacation home generating more stress than income?
          </Typography>
          <Typography variant="p1m" color="midnightBlue.main">
            Swap your home for shares of the Overmoon 721 Fund to defer taxes,
            receive distributions, and maintain appreciation potential.
          </Typography>
          <Box>
            <TrackedButton
              to={ONBOARDING_PATH}
              size="small"
              sx={{
                width: 'unset!important',
              }}
            >
              Get Started
            </TrackedButton>
          </Box>
        </Grid>
      </Grid>
    </SectionLayout>
    <Box position="relative" zIndex={999}>
      <Box
        position="absolute"
        component="img"
        src={HeroImage}
        width="277px"
        height="400px"
        sx={{
          marginTop: '-340px',
          right: '0px',
        }}
      />
    </Box>
  </>
)

export default HomepageHeroSectionMobile
