import React from 'react'
import PageWrapper from '../components/SharedComponents/PageWrapper'
import HomepageBenefitsSection from '../components/HomepageComponents/HomepageBenefitsSection'
import HomepageHeroSection from '../components/HomepageComponents/HomepageHeroSection/HomepageHeroSection'
import HomepageProcessSection from '../components/HomepageComponents/HomepageProcessSection'

const HomepageHowItWorksSection = React.lazy(
  () => import('../components/HomepageComponents/HomepageHowItWorksSection')
)

const HomepagePortfolioSection = React.lazy(
  () => import('../components/HomepageComponents/HomepagePortfolioSection')
)

const pageTitle = 'Overmoon | 721 Fund'
const pageDescription = `Swap your home for shares of the Overmoon 721 Fund to defer taxes, receive distributions, and maintain appreciation potential.`

export const Head = () => (
  <>
    <title>{pageTitle}</title>
    <meta title="title" property="og:title" content={pageTitle} />
    <meta
      name="description"
      property="og:description"
      content={pageDescription}
    />
  </>
)

const Home = () => {
  const isSSR = typeof window === 'undefined'
  return (
    <PageWrapper title={pageTitle} trackingName="homepage">
      <HomepageHeroSection />
      {!isSSR && (
        <React.Suspense fallback="">
          <HomepageBenefitsSection />
          <HomepageHowItWorksSection />
          <HomepagePortfolioSection />
        </React.Suspense>
      )}
      <HomepageProcessSection />
    </PageWrapper>
  )
}

export default Home
