import React from 'react'

import HomepageHeroSectionDesktop from './HomepageHeroSectionDesktop'
import HomepageHeroSectionTablet from './HomepageHeroSectionTablet'
import HomepageHeroSectionMobile from './HomepageHeroSectionMobile'
import useHomepageHeroSection from './useHomepageHeroSection'
import { HomepageHeroSectionProps } from './homepageHeroSectionTypes'

const HomepageHeroSection = (props: HomepageHeroSectionProps) => {
  const presentationalProps = useHomepageHeroSection(props)
  const { isMobile, isTablet } = presentationalProps

  if (isMobile) {
    return <HomepageHeroSectionMobile />
  }
  if (isTablet) {
    return <HomepageHeroSectionTablet {...presentationalProps} />
  }
  return <HomepageHeroSectionDesktop {...presentationalProps} />
}

export default HomepageHeroSection
