import { OmCalendarIcon, OmIncomeIcon, OmInvestIcon } from '@flock/shared-ui'
import { Box, Grid, useMediaQuery, useTheme } from '@mui/material'
import React from 'react'
import Fade from 'react-reveal/Fade'
import Carousel from '../SharedComponents/Carousel'
import IconTextDetail, {
  IconTextDetailProps,
} from '../SharedComponents/IconTextDetail'
import SectionLayout from '../SharedComponents/SectionLayout'

const HomepageBenefitsSection = () => {
  const benefits = [
    {
      icon: OmCalendarIcon,
      title: <>Tax Efficient</>,
      description:
        'Unlike a traditional sale, which triggers significant tax consequences, you now have the ability to exchange your home at fair market value for shares in the Overmoon 721 Fund.',
      color: 'mulberry',
    },
    {
      icon: OmIncomeIcon,
      title: <>Monthly Dividends</>,
      description: `Once you've exchanged your home for shares of the Overmoon 721 Fund, we take over the headaches of property management, maintenance, taxes, and insurance, and you participate in regular distribution payments.`,
      color: 'mulberry',
    },
    {
      icon: OmInvestIcon,
      title: <>Appreciation Potential</>,
      description: `Unlike a one-time payout from a traditional sale, your Overmoon 721 Fund investment still benefits from long-term growth in real estate values.`,
      color: 'mulberry',
    },
  ]

  const theme = useTheme()
  const isTablet = useMediaQuery(theme.breakpoints.down('md'))

  if (isTablet) {
    return (
      <SectionLayout name="benefits" backgroundColor="gray1.main" fullWidth>
        <Grid item xs={12} display="flex" gap="32px">
          <Carousel>
            {benefits?.map((iconTextDetailProps: IconTextDetailProps) => (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                maxWidth="340px"
                margin="auto"
              >
                <IconTextDetail
                  key={iconTextDetailProps.description}
                  {...iconTextDetailProps}
                />
              </Box>
            ))}
          </Carousel>
        </Grid>
      </SectionLayout>
    )
  }

  return (
    <SectionLayout name="benefits" backgroundColor="gray1.main">
      <Grid item xs={12} display="flex" gap="50px">
        <Fade duration={500} distance="5rem">
          {benefits?.map((iconTextDetailProps: IconTextDetailProps) => (
            <IconTextDetail
              key={iconTextDetailProps.description}
              {...iconTextDetailProps}
            />
          ))}
        </Fade>
      </Grid>
    </SectionLayout>
  )
}

export default HomepageBenefitsSection
